import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from '@material-ui/core/Link';
import vbspam from './../../../images/vbspam.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
    image: {
      width: '75%',
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
);

export default function VBTests() {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      {/* Grid Container MAIN */}
      <Grid container spacing={0}>

        {/* Grid IMAGE */}
        <Grid item>
          <ButtonBase className={classes.image} href="https://www.virusbulletin.com/testing/results/recent/vbspam-email-security/scrollout-f1" target="_blank" >
            <img className={classes.img} alt="complex" src={vbspam} />
          </ButtonBase>
        </Grid>

        {/* Grid Container MIDDLE TEXT */}
        <Grid item xs={12} sm container>

          {/* Grid Container TEXT */}
          <Grid item xs container direction="column" spacing={1}>

            {/* Grid Container TEXT */}
            <Grid item>
              <Typography gutterBottom variant="h5">
                Comparative tests
                </Typography>
              <Typography variant="subtitle1" >
                Last ten VBSpam tests for  Scrollout
                </Typography>
            </Grid>

            {/* Grid BUTTON */}
            <Grid item>
              <Typography variant="body1" style={{ cursor: 'pointer' }}>
                <Link href="https://www.virusbulletin.com/testing/results/recent/vbspam-email-security/scrollout-f1" target="_blank">See More</Link>
              </Typography>
            </Grid>
          </Grid>

          {/* Grid PRICE */}
          <Grid item>
            <Typography variant="subtitle1"></Typography>
          </Grid>

        </Grid>
      </Grid>

    </div>
  );
}    

import { makeStyles, fade, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableRows from './home_Requirements.json';


const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: "none",
            color: theme.palette.text.secondary,
        },
        head: {
            color: theme.palette.text.primary,
            backgroundColor: fade(theme.palette.background.default, 0.5),
        },

    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: fade(theme.palette.background.default, 0.5),
            },
        },
    }),
)(TableRow);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(0deg)',
        },
        table: {
            minWidth: 'auto',

        },
    }),
);



export default function Requirements() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Typography variant="h5" component="h2">
                System requirements
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
                Note: OpenVZ not supported
            </Typography>

            <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Resources</StyledTableCell>
                            <StyledTableCell align="right">Up to 5K msgs./day</StyledTableCell>
                            <StyledTableCell align="right">Up to 15K msgs./day</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {TableRows.map((row, id) => (
                            <StyledTableRow key={id}>
                                <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                                <StyledTableCell align="right">{row.min}</StyledTableCell>
                                <StyledTableCell align="right">{row.optimum}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
}
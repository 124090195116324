import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LoadVideo from './../../../images/LoadVideos';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        title: {
            color: theme.palette.primary.main,
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: 'lighter'
        },
        subTitle: {
            textAlign: 'center',
        },
        indent: {
            paddingLeft: '1.25rem'
        },
        note: {
            backgroundColor: fade(theme.palette.background.default, 0.5),
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 20,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 10,
            paddingLeft: 10,
        }
    }),
);


export default function Install(): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* Grid Container MAIN */}
            <Grid container spacing={2}>
                {/* Grid title */}
                <Grid item xs={12}>
                    <Typography gutterBottom component="h2" className={classes.title}>
                        Installation guide
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" color="textSecondary" className={classes.subTitle}>
                        The installation is easy even if you are a newbie in Linux. Basic understanding of email routing is required.
                    </Typography>
                </Grid>
                {/* Grid text */}
                <Grid item md={8}>

                    <Typography gutterBottom variant="overline" color="textSecondary">
                        Estimated time: 30 minutes.
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        A simpler alternative to this article is to use the customized <Link href="https://sourceforge.net/projects/scrollout/files/latest/download" target="_blank">ISO image</Link> (recommended)
                        <br />
                    </Typography>
                    <Typography className={classes.note} gutterBottom variant="body1" color="textSecondary">
                        <b>Important:</b>
                        <br />Make sure you provide valid network settings in order to connect to the Internet during installation.
                        <br />Do not attempt to install on an existing system! You cannot have multiple postfix, spamassassin, clamav instances. Deploy Scrollout F1 on a fresh Debian system.
                        <br />Scrollout F1 is not running on OpenVZ - network configuration incompatibility.
                    </Typography>


                    <Typography variant="h6" color="textSecondary">
                        1. Download Debian Server
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        A clean Debian operating system must be installed. Do not use an existing system on which you have installed other applications and services like an existing web server and email server. The installation process will overwrite the existing settings.
                        Download Debian using <Link href="http://cdimage.debian.org/cdimage/archive/7.8.0/amd64/iso-cd/debian-7.8.0-amd64-netinst.iso" target="_blank">this link</Link>.
                        <br />
                    </Typography>

                    <Typography variant="h6" color="textSecondary">
                        2. Install Debian
                    </Typography>

                    <Typography className={classes.note} gutterBottom variant="body1" color="textSecondary">

                        <b>Note:</b>
                        <br /> When you are asked to configure network connection please make sure you input the correct settings. You need a working Internet connection during installation.

                    </Typography>

                    <Typography variant="h6" color="textSecondary">
                        3. Install Scrollout F1
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">

                        Login to the Debian terminal console using your user and password. For Debian use "root" account. Again, make sure your internet connection is working properly and type the following commands:
                        <br />
                    </Typography>

                    <Typography className={classes.note} gutterBottom variant="body1" color="textSecondary"
                        style={{ overflow: "hidden", textOverflow: "ellipsis", wordBreak: "break-all" }}>

                        <code>
                            apt-get install sudo -y
                            <br />sudo -i
                            <br />cd /tmp
                            <br />sudo apt-get install ca-certificates -yq
                            <br /><br />wget http://sourceforge.net/projects/scrollout/files/update/scrolloutf1.tar/download -O scrolloutf1.tar
                            <br /><br />tar -xvf scrolloutf1.tar
                            <br />chmod 755 /tmp/scrolloutf1/www/bin/*
                            <br />/tmp/scrolloutf1/www/bin/install.sh
                        </code>

                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        Press ENTER every time you are asked to answer a question. By doing this you will select the default answer.
                        <br />
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        When done, the Graphic User Interface URL address and default Admin password will be displayed.
                        <br /><b>URL:</b> http(s)://host-ip/
                        <br /><b>Default user:</b> Admin
                        <br /><b>Default password:</b> 123456
                        <br />To uninstall run: /var/www/bin/uninstall.sh
                        <br />
                    </Typography>
                </Grid>


                {/* Grid image */}
                <Grid item md xs={12}>
                    <LoadVideo
                        url="https://youtu.be/5wwpg4zkjFQ"
                        caption="Video tutorial for installation"
                    />
                </Grid>
            </Grid>
        </div >
    );
}
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ItsValue from './cards/home_ItsValue';
import MostFeatures from './cards/home_Features';
import Requirements from './cards/home_Requirements';
import VBTests from './cards/home_VBtests';
import Donate from './cards/home_Donate';
import Download from './cards/home_Download';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 4,
      marginLeft: 0,
      marginRight: 0,
      padding: 4,
      height: "100%", width: "100%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
  }),
);

export default function EmailGateway(): JSX.Element {
  const classes = useStyles();


  return (
    <div className={classes.root}>

      {/* Container MAIN */}
      <Grid container spacing={0} >

        <Grid item xs={12} container spacing={1}>

          {/* Grid Container left */}
          <Grid item md container direction="column" spacing={1}>
            <Grid item>
              <Paper className={classes.paper}>
                <ItsValue />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <MostFeatures />
              </Paper>
            </Grid>
          </Grid>

          <Grid item md={4} container direction="column" spacing={1}>
            <Grid item>
              <Paper className={classes.paper}>
                <Requirements />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Download />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Donate />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <VBTests />
              </Paper>
            </Grid>
          </Grid>

        </Grid>
      </Grid>

    </div>

  );
}

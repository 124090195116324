import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';



export default function Download() {
    return (
        <div>

            <Typography variant="h5" component="h2">
                Download
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
                Available as 64bit only
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                <br />• <Link href="https://sourceforge.net/projects/scrollout/files/latest/download" target="_blank">ISO image</Link> (Internet connection required during installation)
                <br />• Install from scratch (Internet connection required during installation).
            </Typography>


        </div>

    );
}

import { createMuiTheme } from '@material-ui/core/styles';

export const themeLight = createMuiTheme({
    palette: {
        type: "light",
        text: {
            primary: 'rgba(0, 0, 0, 1)',
            secondary: 'rgba(0, 0, 0, 0.8)'
        },
        primary: {
            light: '#757ce8',
            main: '#1777f2',
            dark: '#002884',
            contrastText: 'rgba(0,0,0,1)',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: 'rgba(0,0,0,0.5)',
        },
        background: {
            paper: '#fff',
            default: '#d5d4d0'
        }
    },
    shape: {
        borderRadius: 0
    },
    props: {
        MuiAppBar: {
            elevation: 5,
        },
        MuiPaper: {
            elevation: 3,
        },
        MuiContainer: {
            maxWidth: false,
        },
        MuiButton: {
            disableRipple: true,
        },
        MuiCheckbox: {
            disableRipple: true
        },
        MuiIconButton: {
            disableRipple: true
        },
        MuiTab: {
            disableRipple: true,
        },
        MuiCardActionArea: {
            disableRipple: true,
        },
        MuiCard: {
            elevation: 0
        },
        MuiTypography: {
            align: 'left',
        },
        MuiGrid: {
            // alignItems: 'flex-start',
            // justify: 'space-between'

        }
    },
    typography: {
        fontFamily: "Segoe UI, Verdana, Roboto, Helvetica, Arial, sans-serif",
        body1: {
            // lineHeight: 2
        },
        body2: {
            // lineHeight: 1.5
        },
        button: {
            textTransform: 'none',
        },
    },
});

export const themeDark = createMuiTheme({
    palette: {
        type: "dark",
        text: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(255, 255, 255, 0.8)'
        },
        primary: {
            light: '#757ce8',
            main: '#5599ff',
            dark: '#002884',
            contrastText: 'rgba(255,255,255,1)',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: 'rgba(255,255,255,0.5)',
        },
        background: {
            paper: '#212121',
            default: '#191919'
        },
    },
    shape: {
        borderRadius: 0
    },
    props: {
        MuiAppBar: {
            elevation: 5,
        },
        MuiPaper: {
            elevation: 3
        },
        MuiContainer: {
            maxWidth: false,
        },
        MuiButton: {
            disableRipple: true
        },
        MuiCheckbox: {
            disableRipple: true
        },
        MuiIconButton: {
            disableRipple: true
        },
        MuiTab: {
            disableRipple: true,
        },
        MuiCardActionArea: {
            disableRipple: true,
        },
        MuiCard: {
            elevation: 0,
        },
        MuiTypography: {
            align: 'left',
        },
        MuiGrid: {
            // alignItems: 'flex-start',
            // justify: 'space-between',
        }
    },
    typography: {
        fontFamily: "Segoe UI, Verdana, Roboto, Helvetica, Arial, sans-serif",
        body1: {
            // lineHeight: 2
        },
        body2: {
            // lineHeight: 1.5
        },
        button: {
            textTransform: 'none',
        },
    }
});

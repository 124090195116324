import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { themeDark, themeLight } from './../Theme';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import EmailGateway from './EmailGateway';
import Deploy from './Deploy';
import Logo from './../../images/customLogo.png';
import Box from '@material-ui/core/Box';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import NavItems from './Nav.json';


const customStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },

  myMuiTab: {
    minWidth: 0,
    fontSize: 16,
    fontWeight: 'normal',
    '@media (min-width: 600px)': {
      minWidth: 0,
    }
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: 0
    },
  },

  logo: {
    '&:focus': { outline: 'none' },
    border: 'none',
    marginLeft: 10,
    marginTop: 0,
    padding: 0,
    backgroundColor: 'transparent',
    maxWidth: 'auto',
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  byMG: {
    marginLeft: 20,
    fontSize: 10,
    marginTop: -10,
  },

  image: {
    maxWidth: 150,
  },
}));


interface Props {
  children: React.ReactElement;
}

function HideOnScroll({ children }: Props) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;
  const classes = customStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container className={classes.container}>
          <Box>
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}


export default function Navigation(): JSX.Element {
  const [value, setValue] = React.useState(JSON.parse(localStorage.getItem('TabID') || '0'));
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    localStorage.setItem('TabID', JSON.stringify(newValue));
  };

  const [customTheme, changeTheme] = React.useState(JSON.parse(localStorage.getItem('themeDark') || 'true'));

  function setTheme(): void {
    changeTheme(!customTheme);
    localStorage.setItem('themeDark', JSON.stringify(!customTheme));
  }

  const activeTheme = createMuiTheme(customTheme ? themeDark : themeLight);
  const classes = customStyles();

  return (
    <ThemeProvider theme={activeTheme} >
      <CssBaseline />

      <HideOnScroll>
        <Box position="relative" bgcolor="background.paper" zIndex="tooltip" color='text.disabled'>
          <button style={{ cursor: 'pointer' }} className={classes.logo}><img src={Logo} alt="Scrollout"
            className={classes.image} onClick={() => setTheme()} />
          </button>
        </Box>
      </HideOnScroll>

      <AppBar position="sticky" color="inherit" title="Menu">
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs"
        >
          {NavItems.map((item, id) => (
            <Tab key={id} className={classes.myMuiTab} label={item.tab} title={item.tab} />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        < EmailGateway />
      </TabPanel>

      <TabPanel value={value} index={1}>
        < Deploy />
      </TabPanel>

      <TabPanel value={value} index={2}>
        Safe Senders
      </TabPanel>

      <TabPanel value={value} index={3}>
        Reputation
      </TabPanel>

      <TabPanel value={value} index={4}>
        Pyzor
      </TabPanel>

      <TabPanel value={value} index={5}>
        Support
      </TabPanel>


    </ThemeProvider >
  );
}

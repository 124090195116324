import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Install from './cards/deploy_Install';
import Configure from './cards/deploy_Configure';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 4,
      marginLeft: 0,
      marginRight: 0,
      padding: 4,
      height: "100%", width: "100%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
  }),
);

export default function Deploy(): JSX.Element {
  const classes = useStyles();


  return (
    <div className={classes.root}>

      {/* Container MAIN */}
      <Grid container spacing={0} >

        <Grid item xs={12} container spacing={1}>

          {/* Grid Container left */}
          <Grid item md container direction="column" spacing={1}>
            <Grid item>
              <Paper className={classes.paper}>
                <Install />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Configure />
              </Paper>
            </Grid>
          </Grid>



        </Grid>
      </Grid>

    </div>

  );
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        expand: {
            transform: 'rotate(deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(0deg)',
        },
        image: {
            maxWidth: '100%',
        },
        appBar: {
            position: 'relative',
        },
        titleBar: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function LoadImage(props: any): JSX.Element {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [elevation, setElevation] = React.useState(3);
    function elevationUp(): void {
        setElevation(13);
    }
    function elevationDown(): void {
        setElevation(3);
    }
    function handleClickOpen(): void {
        setOpen(true);
    }
    function handleClose(): void {
        setOpen(false);
    }
    return (
        <div className={classes.root}>
            <Paper elevation={elevation} onMouseOver={elevationUp} onMouseLeave={elevationDown}>
                <Button onClick={handleClickOpen}>
                    <img className={classes.image} alt={props.ImageTitle} src={props.ImageName} />
                </Button>
            </Paper>

            <Typography gutterBottom variant="caption" color="textSecondary">
                {props.caption}
            </Typography>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar color="inherit" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.titleBar}>
                            {props.ImageTitle}
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Button autoFocus color="inherit" onClick={handleClose}>
                    <img className={classes.image} alt={props.ImageTitle} src={props.ImageName} />
                </Button>
            </Dialog>
        </div >
    )
}
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(0deg)',
        },
        title: {
            color: theme.palette.primary.main,
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: 'lighter'
        },
        subTitle: {
            textAlign: 'center',
        },
        largeText: {
            fontSize: '1.5rem',
            color: theme.palette.text.secondary,
            fontWeight: 'lighter'
        }
    }),
);

export default function ItsValue(): JSX.Element {
    const classes = useStyles();


    return (
        <div className={classes.root}>

            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                Its value is simplicity
            </Typography>

            <Typography gutterBottom variant="subtitle1" color="textSecondary" className={classes.subTitle}>
                Built to meet common practical requirements, makes it ready within 30 minutes
            </Typography>

            <Typography gutterBottom variant="subtitle1" color="textSecondary">
                <span className={classes.largeText}>Designed for Linux & Windows administrators</span> without advanced email security experience. Its goal is to be as simple as possible.
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                An <span className={classes.largeText}>easy to use, already adjusted</span> email gateway (firewall) offering free anti-spam, anti-virus protection in order to secure all existing email servers, such as Microsoft Exchange, Lotus Domino, Postfix, Exim, Qmail and more.
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                Basic <span className={classes.largeText}>deployment in no time:</span> In order to start using this application you need to cover only two steps, described in basic configuration.
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                Built-in multilayer security levels make <span className={classes.largeText}>adjustment effort equal to a car radio.</span>
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                An independent and affordable email security solution with <span className={classes.largeText}>no migration requirements</span> and no cost for implementation and post-maintenance.
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                No limits: protect <span className={classes.largeText}>unlimited domain names and email servers</span> under one roof.
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                <span className={classes.largeText}>Combines simplicity with effective protection</span> using powerful (mature, flexible and scalable) open source tools with additional set of rules & filters.
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
                <span className={classes.largeText}>Helps saving time and money</span> in 100+ Countries with 2,000+ currently active installations protecting tens of thousands of domains in: United States Germany United Kingdom Netherlands Canada Russia Italy Switzerland France Sweden Indonesia New Zealand Poland Denmark Israel Belgium Bulgaria Austria Brazil Turkey India Slovakia Korea Slovenia Serbia Hungary Taiwan China Greece Ireland Argentina Norway Australia Chile Hong Kong Spain Philippines Saudi Arabia Latvia Pakistan Qatar United Arab Emirates Malaysia  and <Link href="https://sourceforge.net/projects/scrollout/files/stats/map?dates=" target="_blank">more.</Link>
            </Typography>

        </div>
    );
}
import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LoadVideo from './../../../images/LoadVideos';
import LoadImage from './../../../images/LoadImages';
import Connection from './../../../images/connection.jpg';
import Graph from './../../../images/graph.jpg';
import Traffic from './../../../images/traffic.jpg';
import DLP from './../../../images/dlp.jpg';
import Logs from './../../../images/logs.jpg';
import Countries from './../../../images/countries.jpg';
import Collector from './../../../images/collector.jpg';
import Security from './../../../images/security.jpg';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        title: {
            color: theme.palette.primary.main,
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: 'lighter'
        },
        indent: {
            paddingLeft: '1.25rem'
        },
        image: {
            maxWidth: '100%',
            padding: 10

        },
        note: {
            backgroundColor: fade(theme.palette.background.default, 0.5),
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 20,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 10,
            paddingLeft: 10,
        }
    }),
);



export default function Configure(): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* Grid Container MAIN */}
            <Grid container spacing={2}>
                {/* Grid title */}
                <Grid item xs={12}>
                    <Typography gutterBottom component="h2" className={classes.title}>
                        Configuration guide
                    </Typography>
                </Grid>
                {/* Grid text */}
                <Grid item md={8}>


                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />An email gateway (a.k.a. firewall) is a machine (physical or virtual) installed between Internet and the email server.
                        <br />Its primary role is to protect the email server by filtering incoming messages, via SMTP protocol, from Internet.
                        <br />Secondary, an email gateway may be used as an outgoing gateway in case you want to add some new email features which are not provided by older email servers. For instance, Exchange 2000 or 2003 has no DKIM signing and verification service - a quality improvement for message delivery.
                    </Typography>

                    <Typography className={classes.note} gutterBottom variant="body1" color="textSecondary">
                        <b>Note:</b>
                        <br />
                        In order to start using this application you must cover only the two steps of BASIC CONFIGURATION (points 1.1 and 1.2 below).
                    </Typography>
                    <Divider />
                    <Typography gutterBottom variant="h4" color="textSecondary">
                        1. Basic configuration
                    </Typography>

                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        1.1 CONNECT
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        Set the network connection: IP address, subnet mask, gateway and DNS.
                        <br />
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Connection}
                            ImageTitle="Connection"
                            caption="Connection panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        1.2 TRAFFIC
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        You need to mention your domains and the responsible email server for each domain.
                        <br />

                        Each domain must be unique, but the servers can have same value in case you are using one email server for multiple domains.
                        <br />

                        The gateway system will become responsible for receiving emails that are addressed to all domains mentioned in this page and will forward the messages to the email servers.
                        <br />

                        Optionally, in case you want to use the gateway for sending outgoing messages, Scrollout offers a DKIM signature and the values that are necessary to be used in your DNS server for each domain.
                        <br />

                        In case that your email servers are using IP addresses different than standard intranet CIDR (10.0.0.0/8, 192.168.0.0/24, 172.16.0.0/12) you must mention the range or IP in CIDR format by clicking “OUTBOUND”.
                        <br />

                        Note, this requires some time to process and the web interface will be provided before finishing the task in order to allow you to make other settings in the meantime.
                        <br />

                        Now you are ready to point your incoming SMTP traffic to Scrollout box. This can be done via an existing router (or firewall) or by modifying DNS MX records for each domain. The first method is much simpler.
                        <br />
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Traffic}
                            ImageTitle="Traffic"
                            caption="Route panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography gutterBottom variant="h4" color="textSecondary">
                        2. Advanced configuration
                    </Typography>

                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.1 SECURE
                    </Typography>

                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.1.1 LEVELS
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        You can set the aggressiveness by clicking on a number between 1 and 10 (green is aggressive, red is permissive). Each filter is explained in the web gui.
                        <br />
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Security}
                            ImageTitle="Security"
                            caption="Secure panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.1.2 COUNTRIES
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        Set the geographical area in which you have business, you may have business or you are 100% sure you will never have any business (contact).
                        <br />
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Countries}
                            ImageTitle="Countries"
                            caption="Countries panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.2 COLLECT
                    </Typography>

                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.2.1 SPAM & LEGIT has three roles:
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br /><b>Quarantine role:</b> Indicates the quarantine mailbox hosted on your email server. All spam and infected messages will be recorded in this mailbox along with a reporting email.
                        <br />
                        <br /><b>Feeder role:</b> Scrollout F1 can learn from legit and spam messages, block sender email address and whitelist the sender domain using same Collector mailbox that is used for quarantine. Using an IMAP Client like MS Outlook you can easily drag & drop multiple emails into mailbox folders.In order to use the feeder feature, you need to: - Create two additional folders under this mailbox. Lets create a GOOD folder and a BAD folder. - Activate IMAP service on your email server. - Input the name, user & password of the mailbox, server IMAP address, and the GOOD and BAD folders.In case you created subfolders (instead of folders) under Inbox, you need to mention Inbox.GOOD and Inbox.BAD. Never use standard folders like Inbox and Sent Items. Scrollout will delete the messages older than 7 days.
                        <br />
                    </Typography>

                    <Typography className={classes.note} gutterBottom variant="body1" color="textSecondary">
                        <br />
                        <b>Tip 1:</b> With Microsoft Office Outlook you can open multiple mailboxes simultaneously. This method allows you to easily drag & drop multiple emails from other mailboxes in GOOD & BAD folders.
                        <br />
                        <br />
                        <b>Tip 2:</b> The Collector mailbox can be opened by multiple users via IMAP and they can share the GOOD and BAD folders. Depending on the permissions set they can read, contribute, delete etc.
                        <br />
                        <br />
                        <b>Tip 3:</b> Never whitelist public email providers (yahoo, gmail, hotmail etc.). By doing that, you will allow a large number of spammers to send junk emails. In case you did that, find spam emails originating from those domains and drag them in BAD folder.
                        <br />
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br /><b>Spam traps role:</b> its goal is to infect spammers' databases with traps (alphabetically). Press "Get code" and hide the email addresses from the csv file into your web page. In approximately 1 month you will receive spam in the Collector's Inbox which can be used to feed Scrollout F1.
                        <br />
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Collector}
                            ImageTitle="Collector"
                            caption="Collect panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.2.2 (DISCONTINUED) LiteDLP can be used to:
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />- block files using MD5 signatures. In case the file is modified, it will not be blocked. It is useful to block files that cannot be blocked by content filters and are rarely modified: e.g.  personal sensitive pictures, designs, scanned documents without text etc.
                        <br />
                        <br />- detect and block keywords and phrases in MS Word, Excel, PowerPoint, PDF and scanned documents containing text. The sensitive email must reach the score level set in Security  LiteDLP, . The score is the sum of all keywords and phrases found in the entire email content (sum of all results found in all attachments + email body). In this way, we cover the cases when the sender breaks a document in multiple pieces in order to trick the scoring. In order to provide the files and phrases to be blocked by Scrollout F1, you need to share a folder on a Windows PC or Server and provide write permissions for the account mentioned in LiteDLP page. LiteDLP is mainly addressed to managers and leaders, not to IT staff. Thus, you may provide write permission to a Management\Leadership Group (in Active Directory) for the shared folder. In addition, LiteDLP attempts to detect compressed files and files without extension type (including multimedia files).
                        <br />
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={DLP}
                            ImageTitle="DLP"
                            caption="LiteDLP panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.4 MONITOR
                    </Typography>

                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.4.1 LOGS
                    </Typography>

                    <Typography className={classes.indent} gutterBottom variant="body1" color="textSecondary">
                        <br />Watch traffic in action.
                    </Typography>
                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Logs}
                            ImageTitle="Logs"
                            caption="Logs panel"
                        />
                    </Grid>

                    <Divider />
                    <Typography variant="h6" color="textSecondary" className={classes.indent}>
                        2.4.2 STATS
                    </Typography>

                    <Grid item xs={12} md={6} lg={4} className={classes.indent}>
                        <LoadImage
                            ImageName={Graph}
                            ImageTitle="Graph"
                            caption="Stats panel"
                        />
                    </Grid>
                    <Typography className={classes.note} gutterBottom variant="body1" color="textSecondary">
                        Scrollout F1 is under development. You will receive automatic updates with each improvement.
                    </Typography>



                </Grid>


                {/* Grid Video */}
                <Grid item md xs={12}>
                    <LoadVideo
                        url="https://youtu.be/Yjea3jEmDvA"
                        caption="Video tutorial for configuration"
                    />

                </Grid>


            </Grid>
        </div >
    );
}
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Features from './home_Features.json';
import Grid from '@material-ui/core/Grid';
import UIupdate from './../../../images/UI update.png';
import LoadImage from './../../../images/LoadImages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(0deg)',
        },
        image: {
            maxWidth: '100%',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);


export default function MostFeatures() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* Grid Container MAIN */}
            <Grid container spacing={2}>

                {/* Grid image */}
                <Grid item md>

                    <LoadImage
                        ImageName={UIupdate}
                        ImageTitle="UI Update"
                        caption="Secure panel"
                    />
                </Grid>

                {/* Grid text */}
                <Grid item md={7}>
                    <Typography variant="h5" component="h2">
                        Features
                            </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Most features are automated
                            </Typography>

                    <Typography variant="body2" color="textSecondary">
                        {Features.map((row, id) => (
                            <span key={id}><br />• {row.detail}</span>
                        ))}
                    </Typography>
                </Grid>

            </Grid>

        </div >

    );
}
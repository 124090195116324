import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ReactPlayer from "react-player"
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        image: {
            maxWidth: '100%',
            padding: 10
        },
    }),
);

export default function LoadVideo(props: any) {
    const classes = useStyles();
    const [elevation, setElevation] = React.useState(3);
    function elevationUp(): void {
        setElevation(13);
    }
    function elevationDown(): void {
        setElevation(3);
    }
    return (
        <div className={classes.root}>

            <Paper className={classes.image} elevation={elevation} onMouseOver={elevationUp} onMouseLeave={elevationDown}>
                <ReactPlayer
                    url={props.url}
                    width='100%'
                />
            </Paper>
            <Typography gutterBottom variant="caption" color="textSecondary">
                {props.caption}
            </Typography>
        </div >
    )
}

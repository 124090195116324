import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
  }),
);


export default function Donate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>

        {/* Grid IMAGE */}
        <Grid item xs={2}>
          <ThumbUpOutlinedIcon fontSize='large' />
        </Grid>

        {/* Grid Container MIDDLE TEXT */}
        <Grid item xs={10} md container>


          <Typography gutterBottom variant="h5" component="h2">
            Contribute to the project
            </Typography>
          <Typography variant="subtitle1" >
            Donate to keep this project free using PayPal: <Link href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TSGC9M53JPVCQ" target="_blank">USD</Link> or <Link href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=H392JXKWPSNGY" target="_blank">EUR</Link>
          </Typography>
          <Typography variant="subtitle1" >
            If you are you using Scrollout F1, please <Link href="http://sourceforge.net/projects/scrollout/reviews/" target="_blank">review here.</Link>
          </Typography>

        </Grid>
      </Grid>

    </div >
  );
}    
